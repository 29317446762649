import React, { FunctionComponent, useEffect, useState } from 'react';
import CalibrationEstimatesPage, { Row } from '../../view/component/page/CalibrationEstimatesPage';
import { getCalibrationEstimatesIdPagePath } from '../../lib/app-path';
import { CallabtSDK } from 'callabt-sdk';
import PageWrapper from '../../lib/PageWrapper';
import { navigate } from 'gatsby';

const CalibrationEstimates: FunctionComponent = () => {
  const [rows, setRows] = useState<Row[]>([]);
  const fetch = async () => {

    const { calibrationEstimates } = await CallabtSDK.getCalibrationEstimates();

    setRows(calibrationEstimates);
  };

  const onClickEdit = (id: number) => {
    navigate(getCalibrationEstimatesIdPagePath({ id }));
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <PageWrapper>
      <CalibrationEstimatesPage
        rows={rows}
        onClickEdit={onClickEdit}
      />
    </PageWrapper>
  );
};

export default CalibrationEstimates;
