import React, { FunctionComponent } from 'react';

import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';

interface Props {
  rows: Row[];
  onClickEdit: (id: number) => void;
}

export interface Row {
  id: number;
  createdAt: string;
  title: string;
  companyName: string;
  requesterName: string;
}

const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const CalibrationEstimatesPage: FunctionComponent<Props> = ({
  rows, onClickEdit,
}) => {
  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>
        견적 요청
      </Typography>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeadCell>ID</StyledTableHeadCell>
              <StyledTableHeadCell align="center">요청 날짜</StyledTableHeadCell>
              <StyledTableHeadCell align="center">제목</StyledTableHeadCell>
              <StyledTableHeadCell align="center">회사 이름</StyledTableHeadCell>
              <StyledTableHeadCell align="center">요청자 이름</StyledTableHeadCell>
              <StyledTableHeadCell align="center"></StyledTableHeadCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id} hover>
                <TableCell component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="center">{row.createdAt}</TableCell>
                <TableCell align="center">{row.title}</TableCell>
                <TableCell align="center">{row.companyName}</TableCell>
                <TableCell align="center">{row.requesterName}</TableCell>
                <TableCell align="center">
                  <Button variant="contained" color="secondary" onClick={() => onClickEdit(row.id)}>
                    <EditIcon style={{ fontSize: '17px' }} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default CalibrationEstimatesPage;
